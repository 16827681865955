@forward "./main-scss/";
@forward "./listing/listing-common";
@forward "./partials/pagination";
@use "./config/" as *;
.waf-listing {
    @extend %p-4;
    .waf-footer,
    .filter-section,
    .loadmore-wrap {
        @extend %d-none;
    }
    .waf-body {
        @extend %half-radius;
        @extend %p-4;
        @extend %pure-white-900-bg;
    }
    .waf-head {
        @extend %pb-8;
        .title {
            @extend %font-20-pb;
        }
        .head-tab {
            @extend %d-none;
        }
    }
    .first-list {
        .article-title {
            --_listing-title: 20;
        }
        .article-meta {
            @extend %pt-6;
        }
    }
    .article {
        &-description {
            @extend %d-none;
        }
        &-content {
            padding-inline: var(--space-0);
            padding-block: var(--space-4);
        }
        &-title {
            @extend %neutral-800;
        }
        &-meta {
            .meta {
                font-size: 1rem;
                font-weight: 500;
                @extend %neutral-500;
                &-author {
                    line-height: 1.6rem;
                }
                &-category {
                    top: var(--space-4);
                }
            }
        }
    }
    .second-list {
        @include listing-card(horizontal);
        .article {
            &-item {
                border-radius: 0;
                @extend %mb-6;
            }
            &-content {
                position: relative;
                @extend %pl-3;
                @extend %pt-3;
                @extend %pb-0;
            }
            &-title {
                margin-top: var(--space-2);
            }
            &-meta {
                margin-bottom: 0;
                .meta-category {
                    top: 0;
                    left: var(--space-3);
                }
            }
        }
    }
    .pagination {
        height: 4.4rem;
        @extend %px-2;
        @extend %half-radius;
        @extend %primary-50-bg;
        @include flex-config(inline-flex, null, center, center);
        &-wrap {
            flex-wrap: wrap;
            overflow: auto;
            @extend %my-4;
            @extend %flex-c-n;
        }
        &-number {
            @extend %flex;
            a {
                @extend %neutral-800;
                @extend %font-14-pb;
                &.active {
                    @extend %primary-300-bg;
                }
            }
        }
        &-result {
            @extend %text-center;
            @extend %w-100;
            @extend %pt-4;
            &-text {
                @extend %font-12-pm;
                @extend %neutral-600;
            }
        }
        a {
            min-width: 3rem;
            width: max-content;
            height: 3rem;
            cursor: pointer;
            padding-inline: .2rem;
            @extend %flex-c-c;
        }
        .nav {
            &-first,
            &-last,
            &-left,
            &-right {
                @extend %font-0;
                @extend %neutral-800;
                &:before {
                    font-size: 1.4rem;
                }
            }
        }
        .nav-first {
            &:before {
                @include icon(p-double-arrow-left);
            }
        }
        .nav-last {
            &:before {
                @include icon(p-double-arrow-right);
            }
        }
        .nav-left {
            &:before {
                @include icon(p-arrow-left);
            }
        }
        .nav-right {
            &:before {
                @include icon(p-arrow-right);
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .waf-listing {
        .second-list {
            .item-type-video {
                .timestamp {
                    top: unset;
                    height: 2rem;
                    padding-block: 0;
                    bottom: var(--space-2);
                    left: -13rem;
                }
            }
        }
    }   
}
@media screen and (max-width: 380px) {
    .waf-listing {
        .pagination {
            a {
                min-width: 2.5rem;
                height: 2.5rem;
                width: max-content;
                padding-inline: .2rem;
            }
        }
    }
}
@include mq(col-md) {
    .waf-listing {
        padding: var(--space-6) 0;
        .waf-head {
            .title {
                font-size: 2.4rem;
            }
        }
        .article-meta {
            .meta {
                font-size: 1.2rem;
                line-height: 1.8rem;
            }
        }
        .first-list {
            display: flex;
            flex-wrap: wrap;
            column-gap: var(--space-4);
            row-gap: 0;
            .article-title {
                --_listing-title: 24;
                line-height: 3.6rem;
                margin-top: var(--space-6);
            }
            .article-item {
                width: calc(50% - var(--space-2));
            }
        }
        .second-list {
            @include listing-card(vertical);
            .article {
                &-content {
                    padding-left: 0;
                    padding-block: var(--space-4);
                }
                &-item {
                    margin-bottom: 0;
                }
                &-title {
                    margin-top: var(--space-7);
                    margin-bottom: var(--space-4);
                }
                &-meta {
                    .meta-category {
                        left: 0;
                        top: var(--space-4);
                    }
                }
            }
        }
        .pagination {
            &-wrap {
                overflow: unset;
            }
            a {
                width: 3.4rem;
                height: 3.4rem;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-listing {
        .first-list {
            .article-title {
                line-height: 3rem;
            }
        }
    }
}